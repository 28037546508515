import { useState } from "react";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { differenceInCalendarDays } from "date-fns";

import { isBrowser } from "../../context/helpers";
import { useAuth } from "../../context/AuthProvider";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useOrderHistory from "../../hooks/useOrderHistory";

import HeaderContainer from "../alRewards/HeaderContainer";
import Order from "./orderHistory/Order";
import { ALLoading } from "../ALComponents";

import * as Styles from "./orderHistory/styles.module.scss";

function OrderHistory() {
  const { accountDetails } = useAuth();
  const dict = useLocalizedSentenceDict();

  const [showPastOrdersAll, setShowPastOrdersAll] = useState(false);
  const [showActiveOrdersAll, setShowActiveOrdersAll] = useState(false);

  const { orders, isLoading } = useOrderHistory(0);

  if (!accountDetails && isBrowser) {
    navigate("/account/login");
  }

  const activeOrders = [];
  const pastOrders = [];
  if (orders?.length > 0) {
    orders.forEach((order) => {
      const orderHasSubscription = order.node.lineItems.edges.some(
        (item) => item.node.title === "AL Luxe"
      );
      // We consider active orders the orders from the past 32 days
      if (differenceInCalendarDays(new Date(), new Date(order.node.processedAt)) <= 32) {
        activeOrders.push({ ...order, orderHasSubscription });
      } else {
        pastOrders.push({ ...order, orderHasSubscription });
      }
    });
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.analuisa.com/order-history/" />
        <meta property="og:url" content="https://www.analuisa.com/order-history/" />
      </Helmet>

      <HeaderContainer />

      <div className={Styles.orderHistory}>
        {isLoading && (orders || []).length === 0 && <ALLoading />}
        {!isLoading && (
          <>
            {!activeOrders && !pastOrders && <h4 className="h4">No orders to show.</h4>}
            {activeOrders?.length > 0 && (
              <div className={Styles.activeOrders}>
                <h2 className="h2 h2--bold h2--uc">{dict.get("Active Orders")}</h2>
                <div className={Styles.orderHistory__orders}>
                  {activeOrders.map((order) => (
                    <Order
                      key={order.name}
                      order={order.node}
                      activeOrder
                      orderHasSubscription={order.orderHasSubscription}
                    />
                  ))}
                </div>
                {activeOrders?.length > 2 && (
                  <button
                    onClick={() => setShowActiveOrdersAll(!showActiveOrdersAll)}
                    type="button"
                    className={Styles.toggleView}
                  >
                    {showActiveOrdersAll ? "See less" : "See more"}
                  </button>
                )}
              </div>
            )}
            {pastOrders?.length > 0 && (
              <div>
                <h2 className="h2 h2--bold h2--uc">{dict.get("Past Orders")}</h2>
                <div className={Styles.orderHistory__orders}>
                  {pastOrders.slice(0, showPastOrdersAll ? pastOrders.length : 2).map((order) => (
                    <Order
                      key={order.node.name}
                      order={order.node}
                      orderHasSubscription={order.orderHasSubscription}
                    />
                  ))}
                </div>
                {pastOrders?.length > 2 && (
                  <button
                    onClick={() => setShowPastOrdersAll(!showPastOrdersAll)}
                    type="button"
                    className={Styles.toggleView}
                  >
                    {showPastOrdersAll ? "See less" : "See more"}
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default OrderHistory;
